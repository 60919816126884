import React from 'react';
import Layout from '@components/common/Layout';
import Seo from '@components/common/Seo';
import IconCardContainer from '@components/iconCard/IconCardContainer';
import IconCard from '@components/iconCard/IconCard';

import ImageHip from '@assets/images/variant-hip.svg';
import ImageKnee from '@assets/images/variant-knee.svg';
import ImageShoulder from '@assets/images/variant-shoulder.svg';
import ImageNeck from '@assets/images/variant-neck.svg';
import ImageBack from '@assets/images/variant-back.svg';
import ImageFallPrevention from '@assets/images/variant-fall-prevention.svg';
import ImageOsteoporosis from '@assets/images/variant-osteoporosis.svg';

const IndexPage = () => (
  <Layout language="en" segmentId={process.env.GATSBY_SEGMENT_SCREENING_2_PRODUCTION_US as string}>
    <Seo
      title="Screening"
      description="Screening"
      lang="en"
    />

    <IconCardContainer title="Choose a test">
      <IconCard
        title="Shoulder"
        imageSrc={ImageShoulder}
        url="/us/en/shoulder/"
      />
      <IconCard
        title="Back"
        imageSrc={ImageBack}
        url="/us/en/back/"
      />
      <IconCard
        title="Neck"
        imageSrc={ImageNeck}
        url="/us/en/neck/"
      />
      <IconCard
        title="Hip"
        imageSrc={ImageHip}
        url="/us/en/hip/"
      />
      <IconCard
        title="Knee"
        imageSrc={ImageKnee}
        url="/us/en/knee/"
      />
      <IconCard
        title="Fall risk"
        imageSrc={ImageFallPrevention}
        url="/us/en/balance/"
      />
      <IconCard
        title="Osteoporosis"
        imageSrc={ImageOsteoporosis}
        url="/us/en/osteoporosis/"
      />
    </IconCardContainer>
  </Layout>
);

export default IndexPage;
